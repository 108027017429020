import styled from "styled-components";



export const TimeContainer = styled.div`
    display: flex;
    font-size: 10px;
    justify-self: center;
    align-items: center;
    margin-left: auto;
`