import Directory from "../../components/directory/directory.component";

const Home = () => {


    return (
        <Directory/>
    )
}

export default Home