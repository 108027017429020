import styled from "styled-components";

export const EmptyTrash = styled.button`
    border: none;
    background: white;
    cursor: pointer;
    font-weight: bold;
    color: blue;
    &:hover{
        background-color: yellow;
    }
`