import React from 'react';
import Navbar from "../../components/navbar/navbar.component";

const Test = () => {
    return (
        <div>
            <Navbar />
        </div>
    );
};

export default Test;